// Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Navigation = () => {
  const [showDropdown, setShowDropdown] = useState(false);


  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };


  return (
    <div className="navigation" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span className="nav-item">Resources</span>
      {showDropdown && (
        <div className="dropdown">
          <ul>
          <li>
            <Link to="/complaints" className="dropdown-item">Activist Complaints</Link>
          </li>
            <li>
            <Link to ="/threatassessmentsnapshot">Threat Assessment Snapshot</Link>
            </li>
            <li>
            <Link to ="/annualassessment">Annual Assessment</Link>
            </li>
            <li>
            <Link to ="/securityplanning">Security Planning</Link>
            </li>   
            <li>
            <Link to ="/aaalac">AAALAC Accreditation</Link>
            </li>    
            <li>
              <Link to ="/usda">USDA Inspection</Link>
            </li>   

          
            
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navigation;

/*
 <li>
            <Link to ="/weeklyintsumar">Weekly Intsum AR</Link>
            </li>
            <li>
            <Link to ="/weeklyintsumes">Weekly Intsum ES</Link>
            </li>
 */
