import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../CSS/PowerBI.css';

const PowerBI = () => {
    const [access_token, setAccessToken] = useState('');
    
    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setAccessToken(data.accessToken);
            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
            });
    }, []);

    

    return (
        <div className="power-bi">
            <h1 className="stats-header">AAALAC</h1>
            <div className="box">
                <p><i>This list comprises organizations accredited by the Association for Assessment and Accreditation of Laboratory Animal Care (AAALAC). Inclusion on this list does not imply endorsement or guarantee of current accreditation status, as accreditation can change over time.</i></p>
                <p><i>Last update : June 2024</i></p>
                <p>
                    For an up-to-date list, please click:&nbsp;
                    <a 
                    href="https://www.aaalac.org/accreditation-program/directory/directory-of-accredited-organizations-search-result/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ color: '#007BFF', textDecoration: 'underline' }}
                    >
                    AAALAC
                    </a>
                </p>
            </div>

            <style>
                {`
                    iframe {
                        border: none;
                    }
                `}
            </style>
          
        {access_token && (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: '7ced6915-6993-4a74-84df-8bafcf095caf',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=7ced6915-6993-4a74-84df-8bafcf095caf&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    //pageName : 'ReportSectionb9f969caf36ee4e18e90',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                        
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName={"reportClass"}

                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
            )}
        </div>
    );

}

export default PowerBI;