import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import  msalConfig from '../auth/msalConfig.js';
import '../CSS/Navbar.css';
import logo from "../Images/logo.png"
import Navigation from './Navigation.js';
import HighlightsNavigation from './HighlightsNavigation.js';
import React, { useState, useEffect } from 'react';
import {setStorage} from '../Components/Storage.js';
import ReportsDD from './ReportsDropdown.js';

const msalInstance = new PublicClientApplication(msalConfig);

const Navbar = () => {

    const { instance, accounts } = useMsal();
    const [clients, setClients] = useState([]);
    const [clientLink, setClientLink] = useState(null);


    useEffect(() => {
      // Fetch client data from your server
      fetch('/api/hello')
        .then((response) => response.json())
        .then((data) => {
          setClients(data.clients);     
        })
        .catch((error) => {
          console.error('Error fetching client data:', error);
        });
    }, []);

    

    useEffect(() => {
      if (accounts.length > 0 && clients.length > 0) {
          const adUsername = accounts[0].username;
          setStorage('username', adUsername);
          const matchingClient = clients.find(client => client.fields.Email === adUsername);

          /*console.log(matchingClient.fields.Email);
          console.log(accounts[0].username);
          console.log(matchingClient.fields.Company);*/

          if (matchingClient) {
              const isSpecCompany = matchingClient.fields.SpecCompany;

              if (isSpecCompany) {
                  // You might add logic here for specific actions if it's a spec company
                  // For now, set the client link if it's a spec company
                  const company = matchingClient.fields.Company;
                  const link = (
                      <a className="navbar-item" key={company} href={`/${company}`}>
                          {company}
                      </a>
                  );
                  setClientLink(link);
              }
          }
      }
  }, [accounts, clients]);
    
  const isAuthenticated = accounts.length > 0;
    const login = () => {
        if (!isAuthenticated) {
          instance.loginPopup();
        }
      };

    const logout = () => {
    if (isAuthenticated) {
        instance.logout();
    }
    };
    
    return (

        <nav className="navbar">
            <a href="/">
              <img src={logo} className='logo'/>
            </a>
            <div className="navbar"> 
              <a className="navbar-item" href="/">Home</a>
              <ReportsDD className = "navigation"/>
              <a className="navbar-item" href ="/upcoming">Upcoming</a>
              <a className="navbar-item" href ="/orgs">Groups</a>
              <HighlightsNavigation className="navigation"/>
              <a className="navbar-item" href ="/powerbi">Statistics</a>
              {clientLink && (
                  <div className="navbar-item client-link">
                      {clientLink}
                  </div>
              )}
            </div>
            {isAuthenticated ? (
            
            <div className='user-info'>
              <p>Hello, {accounts[0].name}!</p>
              <button className='logout-btn' onClick={logout}>Logout</button>
            </div>
          ) : (
            <div className='user-info'>
              <p>You are not logged in.</p>
              <button className='login-btn' onClick={login}>Login</button>
            </div>
          )}
            
        </nav>
             
    );

}

export default Navbar;