const Privacy = () => {
    return (
        <div style={{ textAlign: 'left', padding: '20px' }}>
            <h1 style={{textAlign: 'center'}}>Privacy</h1>
            <h3>SECTION 1.0 – PRIVACY POLICY</h3>
            <p>
                This privacy notice discloses the privacy practices for Orion Reporting
                (<a 
                    href="https://www.orionreporting.com/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    https://www.orionreporting.com/
                </a>).
                This privacy notice applies solely to information collected by this website. It will notify you of the following:
            </p>
            <ol>
                <li>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</li>
                <li>What choices are available to you regarding the use of your data.</li>
                <li>The security procedures in place to protect the misuse of your information.</li>
                <li>How you can correct any inaccuracies in the information.</li>
            </ol>

            <h3>Section 1.1 – Information Collection, Use, and Sharing</h3>
            <p>
                We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us 
                via email or other direct contact from you. We will not sell or rent this information to anyone.
            </p>
            <p>
                We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any 
                third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order.
            </p>
            <p>
                Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
            </p>

            <h3>Section 1.2 – Your Access to and Control Over Information</h3>
            <p>
                You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address 
                or phone number provided on our website:
            </p>
            <ul>
                <li>See what data we have about you, if any.</li>
                <li>Change/correct any data we have about you.</li>
                <li>Have us delete any data we have about you.</li>
                <li>Express any concern you have about our use of your data.</li>
            </ul>
            <h3>Section 1.3 – Security</h3>
            <p>
                We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.
            </p>
            <p>
                Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by 
                looking for a lock icon in the address bar and looking for “https” at the beginning of the address of the web page.
            </p>
            <p>
                While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information 
                to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we 
                store personally identifiable information are kept in a secure environment.
            </p>

            <h3>Section 1.4 – Registration</h3>
            <p>
                In order to use this website, a user must first complete the registration form. During registration a user is required to give certain information (such as email 
                address). This information is used to contact you about the products/services on our site in which you have expressed interest. At your option, you may also 
                provide demographic information (such as gender or age) about yourself, but it is not required.
            </p>

            <h3>Section 1.5 – Orders</h3>
            <p>
                We request information from you on our order form. To buy from us, you must provide contact information (like name and shipping address) and financial 
                information (like credit card number, expiration date). This information is used for billing purposes and to fill your orders. If we have trouble processing 
                an order, we’ll use this information to contact you.
            </p>

            <h3>Section 1.6 – Cookies</h3>
            <p>
                We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat 
                visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on 
                our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked 
                to any personally identifiable information on our site.
            </p>

            <h2>SECTION 2.0 – TERMS AND CONDITIONS</h2>

<h3>Section 2.1 – Terms</h3>
<p>
    By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and 
    agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from 
    using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.
</p>

<h3>Section 2.2 – Use License</h3>
<p>
    Permission is granted to temporarily download one copy of the materials (information or software) on Information Network Associates, Inc.’s web site 
    for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
</p>
<ul>
    <li>Modify or copy the materials;</li>
    <li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
    <li>Attempt to decompile or reverse engineer any software contained on Information Network Associates, Inc.’s web site;</li>
    <li>Remove any copyright or other proprietary notations from the materials; or</li>
    <li>Transfer the materials to another person or “mirror” the materials on any other server.</li>
</ul>
<p>
    This license shall automatically terminate if you violate any of these restrictions and may be terminated by Information Network Associates, Inc. at 
    any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your 
    possession whether in electronic or printed format.
</p>

<h3>Section 2.3 – Disclaimer</h3>
<p>
    The materials on Information Network Associates, Inc.’s web site are provided “as is”. Information Network Associates, Inc. makes no warranties, express 
    or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, 
    fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Information Network Associates, Inc. 
    does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site 
    or otherwise relating to such materials or on any sites linked to this site.
</p>

<h3>Section 2.4 – Limitations</h3>
<p>
    In no event shall Information Network Associates, Inc. or its suppliers be liable for any damages (including, without limitation, damages for loss of data 
    or profit, or due to business interruption) arising out of the use or inability to use the materials on Information Network Associates, Inc.’s Internet 
    site, even if Information Network Associates, Inc. or an Information Network Associates, Inc. authorized representative has been notified orally or in 
    writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for 
    consequential or incidental damages, these limitations may not apply to you.
</p>

<h3>Section 2.5 – Revisions and Errata</h3>
<p>
    The materials appearing on Information Network Associates, Inc.’s web site could include technical, typographical, or photographic errors. Information 
    Network Associates, Inc. does not warrant that any of the materials on its web site are accurate, complete, or current. Information Network Associates, 
    Inc. may make changes to the materials contained on its web site at any time without notice. Information Network Associates, Inc. does not, however, make 
    any commitment to update the materials.
</p>

<h3>Section 2.6 – Links</h3>
<p>
    Information Network Associates, Inc. has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such 
    linked site. The inclusion of any link does not imply endorsement by Information Network Associates, Inc. of the site. Use of any such linked web site is at 
    the user’s own risk.
</p>

<h3>Section 2.7 – Site Terms of Use Modifications</h3>
<p>
    Information Network Associates, Inc. may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to 
    be bound by the then current version of these Terms and Conditions of Use.
</p>

<h3>Section 2.8 – Governing Law</h3>
<p>
    Any claim relating to Information Network Associates, Inc.’s web site shall be governed by the laws of the State of Pennsylvania without regard to its 
    conflict of law provisions.
</p>
<p>
    If you have any questions or concerns regarding the aforementioned Terms of Use, you can contact the INA Watchroom via telephone at (717)-921-2110 or via email at 
    <a href="mailto:watchroom@ina-inc.com">watchroom@ina-inc.com</a>.
</p>


        </div>
    );
};

export default Privacy;
