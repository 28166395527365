import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../CSS/ContactUs.css';

const ContactUs = () => {
  const form = useRef();

  const [expanded, setExpanded] = useState(null);
  const [expandedRFI, setExpandedRFI] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_dhgtf7o', 'template_2oqlyys', form.current, 'CSxb2cjoKlU_BpSCG')
      .then((result) => {
        form.current.reset(); // Reset the form after successful submission
      })
      .catch((error) => {
        console.log(error.text);
      });
  };


  const toggleQuestion = (index) => {
    if (expanded === index) {
      setExpanded(null); // Collapse the currently expanded question
    } else {
      setExpanded(index); // Expand the clicked question
    }
  };

  const toggleRFI = (index) => {
    if (expandedRFI === index) {
      setExpandedRFI(null); // Collapse the currently expanded question
    } else {
      setExpandedRFI(index); // Expand the clicked question
    }
  };

  const questions = [
    { question: 'Can I request a group profile that is not on the website?', answer: 'Yes, you may complete a "Request for Information (RFI)" to request a group profile at no additional cost.' },
    { question: 'Can I get historical data not on the website?', answer: 'Yes, you may complete a "Request for Information (RFI)", additional fees may apply. See "Pricing" for information.' },
    { question: 'How many years does the website hold for historical data?', answer: 'Orion holds historical data for the past 20+ years, however some unique data is only available through "Request for Information (RFI)".' },
    { question: 'Pricing', answer: 'RFIs may inccur additional fees' },
    { question: 'How long are reports available on the website?', answer: 'Orion holds reports for 1 year in downloadable format. Any historical reports must be requested through a "Request for Information (RFI)".' },
    // Add more questions and answers as needed
  ];

  const RFIC = [
    { question: 'Intelligence Assessment', answer: 'An intelligence report pertaining to a specific requested topic. INA Intelligence Analysts utilize data from various sources, including Open Source Intelligence (OSINT) and Public Record services, to form a cohesive understanding of a specific situation, threat, or opportunity.' },
    { question: 'Amplifying Information', answer: 'Expanded information pertaining to an existing intelligence report produced by INA. This supplemental data aims to offer a more comprehensive and detailed understanding of the subject matter covered in the initial report. It may encompass further analysis, relevant context, recent developments, or any other pertinent information that enhances the overall depth and accuracy of the intelligence provided in the original report.' },
    { question: 'Location Risk Assessment', answer: 'An intelligence report of potential hazards, vulnerabilities, and threats associated with a specific site, location, or country. This comprehensive examination aims to identify factors that could pose risks to people, property, and information. The assessment considers various aspects such as geography, local activism, and other human activities that might contribute to potential risks.' },
    { question: 'Executive/Individual Exposure Report', answer: 'An intelligence report that provides a comprehensive overview of the exposure, risks, or involvement of a particular executive or individual. The report typically includes details about the individual\'s professional or personal engagements, financial interests, affiliations, and potential areas of vulnerability that could be utilized by bad actors targeting the individual.' },
    { question: 'Profile – Organization', answer: 'An examination of the history, practices, and credentials of a specific entity, typically conducted to minimize risks from association with the entity. Key components of a background check on an organization may include financial records, legal history, regulatory compliance, ownership structure, non-profit 501c3 standings, and any relevant affiliations.' },
    { question: 'Investigative Support', answer: 'INA Intelligence Analysts can aid in discovering pertinent information related to subjects of interest, investigations, or litigation support on behalf of our clients. This process assists our clients in making well-informed decisions, navigating complex scenarios, and enhancing their overall situational awareness.' },
    { question: 'Monitoring Request', answer: 'INA Intelligence Analysts can provide live-monitoring to search for activist events. By collecting and analyzing publicly available information from sources such as social media, websites, and news outlets.' },
    { question: 'Other', answer: 'INA possesses the flexibility to tailor its services to meet the unique needs of our clients. Share the details of your request, and we can formulate a customized plan to address your specific situation.' },
    // Add more questions and answers as needed
  ];

  return (
    <div className="contact-us">
    <h1 className="page-header">Contact INA</h1>

    <div className="resources">
      <div className="column">
        <h2>Frequently Asked Questions</h2>
        {questions.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${expanded === index ? 'expanded' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              <strong>{item.question}</strong>
              <span style={{ marginLeft: '5px' }}>{expanded === index ? '\u25B2' : '\u25BC'}</span>
            </div>
            {expanded === index && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </div>
      <div className="column">
      <h3 className="RFI-header">Request For Intelligence (RFI) – Categories </h3>
      {RFIC.map((item, index) => (
    <div key={index} className="faq-item">
        <div
            className={`faq-RFI ${expandedRFI === index ? 'expanded' : ''}`}
            onClick={() => toggleRFI(index)}
        >
            <strong>{item.question}</strong>
            <span style={{ marginLeft: '5px' }}>{expandedRFI === index ? '\u25B2' : '\u25BC'}</span>
        </div>
        {expandedRFI === index && <div className="faq-answer">{item.answer}</div>}
    </div>
))}
        <form ref={form} onSubmit={sendEmail} className="contact-form">
          <div className="form-row">
            <label className="reason-for-contact">Reason For Contact:</label>
            <select name="dropdown" className="form-input-dropdown">
              <option value="">Select an option</option>
              <option value="option1">Intelligence Assessment</option>
              <option value="option2">Amplifying Information</option>
              <option value="option3">Location Risk Assessment</option>
              <option value="option4">Executive/Individual Exposure Report</option>
              <option value="option5">Profile – Organization</option>
              <option value="option5">Investigative Support</option>
              <option value="option5">Monitoring Request</option>
              <option value="option5">Other</option>
            </select>
          </div>
          <div className="form-row">
            <label className="form-label">Name:</label>
            <input type="text" name="user_name" className="form-input" />
          </div>
          <div className="form-row">
            <label className="form-label">Email:</label>
            <input type="email" name="user_email" className="form-input" />
          </div>
          <div className="form-row">
            <label className="form-label">Company:</label>
            <input type="text" name="company" className="form-input" />
          </div>
          <div className="form-row">
            <label className="form-label">Deadline:</label>
            <input type="text" name="deadline" className="form-input" />
          </div>
          <div className="form-row">
            <label className="form-label">Subject:</label>
            <input type="text" name="subject" className="form-input" />
          </div>
          <div className="form-row">
            <label className="form-label">Message:</label>
            <textarea name="message" className="form-textarea" rows={6} cols={50} />
          </div>
          <input className="form-submit" type="submit" value="Send" />
        </form>
      </div>
    </div>
    </div>
  );
};

export default ContactUs;