import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../CSS/Calendar.css';

const localizer = momentLocalizer(moment);

const YourCalendarComponent = () => {
  const [reports, setReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [groupResponsible, setGroupResponsible] = useState('');
  const [targetedEntity, setTargetedEntity] = useState('');
  const [selectedDropdownFilter, setSelectedDropdownFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedIndustryFilter, setSelectedIndustryFilter] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [orgs, setOrgs] = useState([]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value); // Update the search term
  };

  const handleSectorChange = (event) => {
    const sector = event.target.value;
    setSelectedSector(sector === selectedSector ? '' : sector); // Toggle the sector
  };

  const handleGroupResponsibleChange = (event) => {
    setGroupResponsible(event.target.value);
  };

  const handleTargetedEntityChange = (event) => {
    setTargetedEntity(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
    setSelectedSector('');
    setGroupResponsible('');
    setTargetedEntity('');
    setSelectedDropdownFilter('');
    setStartDate('');
    setEndDate('');
    setSelectedIndustryFilter('');
    setSelectedRegion('');
  };

  const applyDropdownFilter = (event) => {
    setSelectedDropdownFilter(event.target.value);
  };

  const applyIndustryFilter = (event) => {
    setSelectedIndustryFilter(event.target.value);
  };

  const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region === selectedRegion ? '' : region); // Toggle the region
  };

  useEffect(() => {
    // Fetch reports data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        // Filter reports where report.fields.field_9 is "Protest Planned"
        const filteredReports = data.reports.filter((report) => report.fields.field_9 === 'Protest Planned');
        setReports(filteredReports);
        setOrgs(data.orgs);
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, []);

  useEffect(() => {
    console.log("Updated orgs state:", orgs); // This will now log correctly
  }, [orgs]); // Runs every time `orgs` changes
/*
  const filterReports = () => {
    return reports.filter((report) => {

      const sector = report.fields.field_6 || [];
      const titleMatches =
        !searchTerm || report.fields.Title?.toLowerCase().includes(searchTerm.toLowerCase());
        const groupResponsibleMatches =
        !groupResponsible || // If no filter is applied, include all reports
        (report.fields.field_10?.toLowerCase().includes(groupResponsible.toLowerCase()) || // Check field_10
          (report.fields.GroupResponsibleLU?.length > 0 &&
            report.fields.GroupResponsibleLU.some(
              (item) =>
                item.LookupValue?.toLowerCase().includes(groupResponsible.toLowerCase())
            ))); // Check GroupResponsibleLU
      const targetedEntityMatches =
        !targetedEntity ||
        (report.fields.field_11 &&
          report.fields.field_11.toLowerCase().includes(targetedEntity.toLowerCase()));
      const dropdownFilterMatches =
        !selectedDropdownFilter ||
        (report.fields.field_9 &&
          report.fields.field_9.toLowerCase().includes(selectedDropdownFilter.toLowerCase()));
      const regionMatches =
        !selectedRegion ||
        (report.fields.Region0 && report.fields.Region0 === selectedRegion);
      const reportDate = new Date(report.fields.field_1_formatted);
      const startDateMatches = !startDate || reportDate >= new Date(startDate);
      const endDateMatches = !endDate || reportDate <= new Date(endDate);
      const industryMatches =
        !selectedIndustryFilter ||
        (report.fields.ARIndustry &&
          report.fields.ARIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.ESIndustry &&
          report.fields.ESIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.PharmaIndustry &&
          report.fields.PharmaIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase()));

      if (
        !selectedSector &&
        !searchTerm &&
        !groupResponsible &&
        !targetedEntity &&
        !selectedDropdownFilter &&
        !selectedRegion
      ) {
        return startDateMatches && endDateMatches && industryMatches; // Return all reports when no filters are selected
      }

      if (selectedSector) {
        const sectorMatches = sector.some((sec) => sec.toLowerCase() === selectedSector.toLowerCase());
        return (
          titleMatches &&
          sectorMatches &&
          groupResponsibleMatches &&
          targetedEntityMatches &&
          dropdownFilterMatches &&
          regionMatches &&
          startDateMatches &&
          endDateMatches &&
          industryMatches
        );
      }

      return (
        titleMatches &&
        groupResponsibleMatches &&
        targetedEntityMatches &&
        dropdownFilterMatches &&
        regionMatches &&
        startDateMatches &&
        endDateMatches &&
        industryMatches
      );
    });
  };

  const filteredReports = filterReports(
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    startDate,
    endDate,
    selectedIndustryFilter,
    selectedRegion
  );
  */

  const filterReports = (
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    startDate,
    endDate,
    selectedIndustryFilter,
    selectedRegion,
    orgs 
  ) => {
    // Normalize the search term
    const groupResponsibleLower = groupResponsible?.toLowerCase();
  
    // Set to store all related Group Responsible names for broader search
    let relatedGroupNames = new Set();
  
    if (groupResponsibleLower && orgs.length > 0) {
      // Collect all possible matching names
      orgs.forEach(org => {
        const title = org.fields?.Title?.toLowerCase();
        const aka = org.fields?.AKA?.toLowerCase();
        const oldName = org.fields?.OldName?.toLowerCase();
  
        // Check if input is part of any of the fields (partial match)
        if (title?.includes(groupResponsibleLower) || 
            aka?.includes(groupResponsibleLower) || 
            oldName?.includes(groupResponsibleLower)) {
          
          // Add all relevant names for broader filtering
          if (title) relatedGroupNames.add(title);
          if (aka) relatedGroupNames.add(aka);
          if (oldName) relatedGroupNames.add(oldName);
        }
      });
  
      // If no exact match found, use the input itself to allow partial search
      if (relatedGroupNames.size === 0) {
        relatedGroupNames.add(groupResponsibleLower);
      }
    }
  
    console.log("Related Group Names (for filtering):", [...relatedGroupNames]);
  
    return (reports || []).filter((report) => {
      if (!report.fields) return false; // Ensure report.fields exists
  
      const sector = report.fields.field_6 || [];
      const searchTermLower = searchTerm?.toLowerCase();
  
      const titleMatches = 
        !searchTerm || 
        report.fields.Title?.toLowerCase().includes(searchTermLower);
  
      // Modify this to allow partial matching for GR search
      const groupResponsibleMatches = 
        !groupResponsible || 
        [...relatedGroupNames].some(name =>
          report.fields.field_10?.toLowerCase().includes(name) || // Partial match in field_10
          (report.fields.GroupResponsibleLU?.some(item =>
            item.LookupValue?.toLowerCase().includes(name) // Partial match in GroupResponsibleLU
          ))
        );
  
      const targetedEntityMatches =
        !targetedEntity ||
        (report.fields.field_11 &&
          report.fields.field_11.toLowerCase().includes(targetedEntity.toLowerCase()));
  
      const dropdownFilterMatches =
        !selectedDropdownFilter ||
        (report.fields.field_9 &&
          report.fields.field_9.toLowerCase().includes(selectedDropdownFilter.toLowerCase()));
  
      const regionMatches =
        !selectedRegion ||
        (report.fields.Region0 && report.fields.Region0 === selectedRegion);
  
      const reportDate = new Date(report.fields.field_1_formatted);
      const startDateMatches = !startDate || reportDate >= new Date(startDate);
      const endDateMatches = !endDate || reportDate <= new Date(endDate);
  
      const industryMatches =
        !selectedIndustryFilter ||
        (report.fields.ARIndustry &&
          report.fields.ARIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.ESIndustry &&
          report.fields.ESIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.PharmaIndustry &&
          report.fields.PharmaIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase()));
  
      if (
        !selectedSector &&
        !searchTerm &&
        !groupResponsible &&
        !targetedEntity &&
        !selectedDropdownFilter &&
        !selectedRegion
      ) {
        return startDateMatches && endDateMatches && industryMatches;
      }
  
      if (selectedSector) {
        const sectorMatches = sector.some((sec) => sec.toLowerCase().includes(selectedSector.toLowerCase()));
        return (
          titleMatches &&
          sectorMatches &&
          groupResponsibleMatches &&
          targetedEntityMatches &&
          dropdownFilterMatches &&
          regionMatches &&
          startDateMatches &&
          endDateMatches &&
          industryMatches
        );
      }
  
      return (
        titleMatches &&
        groupResponsibleMatches &&
        targetedEntityMatches &&
        dropdownFilterMatches &&
        regionMatches &&
        startDateMatches &&
        endDateMatches &&
        industryMatches
      );
    });
  };
  
  // Apply filters
  const filteredReports = filterReports(
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    startDate,
    endDate,
    selectedIndustryFilter,
    selectedRegion,
    orgs // Pass the orgs array for lookup
  );

  

  
  const events = filteredReports.map((report) => ({
    title: report.fields.Title,
    start: moment(report.fields.field_2_formatted).toDate(),  // Start date
    end: report.fields.EndDate_formatted
      ? moment(report.fields.EndDate_formatted).endOf('day').toDate()  // Use EndDate if it exists
      : moment(report.fields.field_2_formatted).toDate(),  // Fallback to field_2_formatted
    url: report.fields.Links1,
  }));
  
  

  const EventComponent = ({ event }) => {
    return (
      <div className="report-item">
        <a href={event.url} target="_blank" rel="noopener noreferrer">
          {event.title}
        </a>
      </div>
    );
  }; 
  

  return (
    <div className="calendar">
      <h1>Planned Protests</h1>

      <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20vh', // This sets the height to the full viewport height
    marginTop: '10vh',
    marginBottom: '14vh'
  }}>
  {/* Search and Sector filter */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      
 
      {/* Sector filter */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <h3>Sector Filter:</h3>
        {/* ... Radio buttons for sectors */}
        <label style={{ color: selectedSector !== 'Animal Rights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Animal Rights"
              checked={selectedSector === 'Animal Rights'}
              onChange={handleSectorChange}
            />
            <span>Animal Rights</span>
          </label>
          <label style={{ color: selectedSector !== 'Energy Sector' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Energy Sector"
              checked={selectedSector === 'Energy Sector'}
              onChange={handleSectorChange}
            />
            <span>Energy Sector</span>
          </label>
          <label style={{ color: selectedSector !== 'Pharmaceuticals' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Pharmaceuticals"
              checked={selectedSector === 'Pharmaceuticals'}
              onChange={handleSectorChange}
            />
            <span>Pharma</span>
          </label>
          <label style={{ color: selectedSector !== 'Critical Insights' ? 'white' : '#cab069' }}>
            <input
              type="radio"
              value="Critical Insights"
              checked={selectedSector === 'Critical Insights'}
              onChange={handleSectorChange}
            />
            <span>Critical Insights</span>
          </label>
      </div>
    </div>
 
    {/* Clear button */}
    <button onClick={handleClear} className="clear-button">Clear</button>
  </div>

   {/* Region Filter */}
   <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <h3>Region Filter:</h3>
          {[
            'North America',
            'Latin America',
            'Western Europe',
            'Eastern Europe',
            'Middle East',
            'Asia',
            'Africa',
            'Oceania',
          ].map((region) => (
            <label
              key={region}
              style={{ color: selectedRegion !== region ? 'white' : '#cab069' }}
            >
              <input
                type="radio"
                value={region}
                checked={selectedRegion === region}
                onChange={handleRegionChange}
              />
              <span className="region-text"> {region}</span>
            </label>
          ))}
        </div>
        
{/* Advanced search row 1 */}
<div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
<label>Search:&nbsp;
      <input
        type="text"
        placeholder="Search by Title"
        value={searchTerm}
        onChange={handleSearch}
      />
      </label>
    {/* Group Responsible search */}
    <label>
      Group Responsible:&nbsp;
      <input
        type="text"
        placeholder="Search by group"
        value={groupResponsible}
        onChange={handleGroupResponsibleChange}
      />
    </label>
 
    {/* Targeted Entity search */}
    <label>
      Targeted Entity:&nbsp;
      <input
        type="text"
        placeholder="Search by targeted entity"
        value={targetedEntity}
        onChange={handleTargetedEntityChange}
      />
    </label>
 
    {/* Type of Report dropdown */}
    <label htmlFor="dropdownFilter">Type of Report:</label>
    <select id="dropdownFilter" value={selectedDropdownFilter} onChange={applyDropdownFilter}>
      <option value="">Select an option</option>
      <option value="protest after-action">Protest After-Action</option>
      <option value="direct action">Direct Action</option>
      <option value="general intel">General Intel</option>
      <option value="infiltration">Infiltration</option>
    </select>
  </div>
 
  {/* Advanced search row 2 */}
  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem'}}>
        {/* Targeted Industry dropdown */}
        <label htmlFor="industryFilter">Targeted Industry:</label>
        <select id="industryFilter" value={selectedIndustryFilter} onChange={applyIndustryFilter}>
            <option value="">Select an option</option>
            <option value="Fur and Leather">Fur and Leather</option>
            <option value="Hunting">Hunting</option>
            <option value="Research – CRO">Research – CRO</option>
            <option value="Research – Academic">Research – Academic</option>
            <option value="Research – Breeder">Research – Breeder</option>
            <option value="Research – General">Research – General</option>
            <option value="Research – Pharmaceuticals">Research – Pharmaceuticals</option>
            <option value="Research – Transportation">Research – Transportation</option>
            <option value="Research – Government">Research – Government</option>
            <option value="Research – Cosmetics">Research – Cosmetics</option>
            <option value="Research – Other">Research – Other</option>
            <option value="Agriculture – General">Agriculture – General</option>
            <option value="Agriculture – Transportation">Agriculture – Transportation</option>
            <option value="Agriculture – Government">Agriculture – Government</option>
            <option value="Agriculture – Food Services">Agriculture – Food Services</option>
            <option value="Agriculture – Farms">Agriculture – Farms</option>
            <option value="Agriculture – Processing">Agriculture – Processing</option>
            <option value="Entertainment – Government">Entertainment – Government</option>
            <option value="Entertainment – Sports">Entertainment – Sports</option>
            <option value="Entertainment – Zoo/Park">Entertainment – Zoo/Park</option>
            <option value="Pet Industry">Pet Industry</option>
            <option value="Coal">ES - Coal</option>
            <option value="Logging">ES - Logging</option>
            <option value="Academic">ES - Academic</option>
            <option value="Financing">ES - Financing</option>
            <option value="Government">ES - Government</option>
            <option value="Fossil Fuels">ES - Fossil Fuels</option>
            <option value="Nuclear">ES - Nuclear</option>
            <option value="Renewables">ES - Renewables</option>
            <option value="Anti-Vaccine">Pharma - Anti-Vaccine</option>
            <option value="Access to Medicine">Pharma - Access to Medicine</option>
            <option value="GMO">Pharma - GMO</option>
            {/* Add more options as needed */}
          </select>
 
    {/* Start Date filter */}
    <label>
      Start Date:&nbsp;
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
        style={{
          height: '30px',
          padding: '0 10px',
          fontSize: '16px',
          lineHeight: '1.5',
          boxSizing: 'border-box',
        }}
      />
    </label>
 
    {/* End Date filter */}
    <label>
      End Date:&nbsp;
      <input
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
        style={{
          height: '30px',
          padding: '0 10px',
          fontSize: '16px',
          lineHeight: '1.5',
          boxSizing: 'border-box',
        }}
      />
    </label>
 
  </div>
</div>  
    <div className="calendar-wrapper">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        allDayAccessor={() => true} // Treat all events as all-day
        views={{ month: true, day: true }} // Enable Month, Week, and Day views
        defaultView="month" // Set the default view (optional)
        defaultDate={new Date()}
        popup // Enable popup for event details
        showMultiDayTimes={false}
        components={{ event: EventComponent }} // Set custom component for event rendering
        onSelectSlot={(slotInfo) => {
          console.log('Day clicked:', slotInfo.start); // Trigger action on click
          // You could manually open a modal or navigate to a different view here
        }}
        eventPropGetter={(event) => {
          const isMultiDay = !moment(event.start).isSame(event.end, 'day');
      
          return {
            style: {
              backgroundColor: isMultiDay ? '#9c6c31' : '#3174ad',  // Orange for multi-day, default blue for single-day
              color: 'white',  // Text color
              borderRadius: '5px',
              border: 'none',
              padding: '2px 4px'
            }
          };
        }}
        
      />
    </div>
    </div>
  );
};

export default YourCalendarComponent;