import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import '../CSS/Highlights.css';

const Highlights = () => {
    const [loading, setLoading] = useState(true); // State to track loading
    const [access_token, setAccessToken] = useState('');
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // State to manage disclaimer visibility

    useEffect(() => {
        // Fetch reports data from your server
        fetch('/api/hello')
            .then((response) => response.json())
            .then((data) => {
                setLoading(false); // Data is fetched, set loading to false
                setAccessToken(data.accessToken);
            })
            .catch((error) => {
                console.error('Error fetching reports data:', error);
                setLoading(false); // Set loading to false in case of error
            });
    }, []);

    // Function to toggle disclaimer visibility
    const toggleDisclaimer = () => {
        setIsDisclaimerOpen(!isDisclaimerOpen);
    };

    return (
        <div className="highlights">
            <h2>USDA Inspection Tracking</h2>

            {/* Disclaimer clickable and expandable */}
            <p 
                style={{ cursor: 'pointer', color: 'white' }} 
                onClick={toggleDisclaimer}
            >
                Disclaimer
                <span style={{ marginLeft: '5px' }}>
                    {isDisclaimerOpen ? '\u25B2' : '\u25BC'}
                </span>
                
            </p>
            {isDisclaimerOpen && (
                <div className="disclaimer-text">
                    <p>
                        The following interactive charts include data pulled from the USDA official site for past inspection reports released, 
                        as well as any APHIS punitive actions, for any entries falling under the following license categories. 
                        As noted below a few of the categories were only pulled from a certain point forward so some historical data will not 
                        be included yet but still available when specifically searched for on the government site. 
                        Going forward we intend to keep at least four years’ worth of data searchable on the website.
                    </p>
                    <p>Inspection Report categories pulled include:</p>
                    <ul className="left-align-list">
                        <li>Breeder (Obtained July 2023 – Present)</li>
                        <li>Research Facility (Obtained July 2022 – present)</li>
                        <li>Agriculture Research Facility (All)</li>
                        <li>Carrier (Obtained August 2022 – present)</li>
                        <li>Dealer (Obtained July 2022 – present)</li>
                        <li>Federal Research Facility (All)</li>
                        <li>Intermediate Handler (Obtained July 2022 – present)</li>
                    </ul>
                    <p>
                        APHIS’s Official Warning categories pulled includes the following. We have obtained all entries available 
                        from the site for these categories:
                    </p>
                    <ul className="left-align-list">
                        <li>Breeder</li>
                        <li>Carrier</li>
                        <li>Research</li>
                        <li>Dealer</li>
                    </ul>
                </div>
            )}

            <style>
                {`
                    iframe {
                        border: none;
                    }
                `}
            </style>

            {access_token && (
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: '435e8a44-f08e-4bf1-954d-6e16ba65993a',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=435e8a44-f08e-4bf1-954d-6e16ba65993a&groupId=8462ee1f-e8db-4f03-bba9-021eabab566f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QyLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
                    accessToken: access_token,
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    pageName : 'ReportSection',
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            }
                        },
                        layoutType: models.LayoutType.Single,
                        //navContentPaneEnabled: false, // This hides the entire page selector
                        background: models.BackgroundType.Transparent,
                        
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName={"reportClass-complaints"}

                getEmbeddedComponent={(embeddedReport) => {
                    window.Report = embeddedReport;
                }}
            />
            )}
        </div>
    );
};

export default Highlights;
