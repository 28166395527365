import React from 'react';
import { Link } from 'react-router-dom';

const OrgsList = ({ orgs, searchTerm, sectorSelected, handleSearch, handleSectorChange, selectedLetter }) => {


  const filteredOrgs = orgs
  .sort((a, b) => a.fields.Title.localeCompare(b.fields.Title))
  .filter((org) => {
    const searchTermMatches = org.fields.Title.toLowerCase().includes(searchTerm.toLowerCase()) || org.fields.AKA && org.fields.AKA.toLowerCase().includes(searchTerm.toLowerCase()) || org.fields.OldName && org.fields.OldName.toLowerCase().includes(searchTerm.toLowerCase());
    const sectorMatches = sectorSelected === '' || org.fields.Sector === sectorSelected;
    const letterMatches = selectedLetter === '' || 
                          (selectedLetter === '#' ? /^[0-9]/.test(org.fields.Title) : 
                          org.fields.Title.toUpperCase().startsWith(selectedLetter));
    const noFiltersSelected = searchTerm === '' && sectorSelected === '' && selectedLetter === '';

    return noFiltersSelected || (searchTermMatches && sectorMatches && letterMatches);
  });
 /* const filteredOrgs = orgs.filter((org) => {
    const searchTermMatches = org.fields.Title.toLowerCase().includes(searchTerm.toLowerCase());
    const sectorMatches = sectorSelected === '' || org.fields.Sector === sectorSelected;
    const letterMatches = selectedLetter === '' || org.fields.Title.toUpperCase().startsWith(selectedLetter);
    const noFiltersSelected = searchTerm === '' && sectorSelected === ''&& selectedLetter === '';


    return noFiltersSelected || (searchTermMatches && sectorMatches && letterMatches);
    }); */
  return (
    <div className="orgs-list">
      <ul>
        {filteredOrgs.map((org) => (
          <li key={org.fields.id}>
            {/* Make each organization item clickable using Link */}
            <Link to={`/orgdetails/${org.fields.id}`} className="list-item-link">
              {org.fields.Title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrgsList;
