import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../CSS/Modal.css'; // Add custom styles for the modal

const EmailFormModal = ({ isOpen, onClose, orgName }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_dhgtf7o', 'template_2oqlyys', form.current, 'CSxb2cjoKlU_BpSCG')
      .then((result) => {
        console.log(result.text);
        form.current.reset(); // Clear the form upon successful submission
        onClose(); // Close the modal
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Contact Us</h2>
        <form ref={form} onSubmit={sendEmail} className="email-form">
          <div className="form-row">
            <label className="form-label">Reason for Contact:</label>
            <input
              type="text"
              name="reason"
              className="form-input"
              value="Profile – Organization"
              readOnly
            />
          </div>
          <div className="form-row">
            <label className="form-label">Name:</label>
            <input type="text" name="user_name" className="form-input" required />
          </div>
          <div className="form-row">
            <label className="form-label">Email:</label>
            <input type="email" name="user_email" className="form-input" required />
          </div>
          <div className="form-row">
            <label className="form-label">Company:</label>
            <input type="text" name="company" className="form-input" required />
          </div>
          <div className="form-row">
            <label className="form-label">Deadline:</label>
            <input type="text" name="deadline" className="form-input" required />
          </div>
          {/* Subject */}
          <div className="form-row">
            <label className="form-label">Subject:</label>
            <input
              type="text"
              name="subject"
              className="form-input"
              defaultValue={`Profile Creation - ${orgName}`} // Prefilled value but editable
              required
            />
          </div>
            {/* Message */}
            <div className="form-row">
            <label className="form-label">Message:</label>
            <textarea
              name="message"
              className="form-textarea"
              rows={6}
              cols={50}
              defaultValue={`Hello,

I would like a profile of ${orgName} created.

Thank you`}
required
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="form-submit">Send</button>
            <button type="button" className="form-cancel" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailFormModal;
