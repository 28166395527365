import React, { useEffect, useState } from 'react';
import '../CSS/ReportsGrid.css';

const ReportsGrid = ({
  searchTerm,
  selectedSector,
  groupResponsible,
  targetedEntity,
  selectedDropdownFilter,
  startDate,
  endDate,
  selectedIndustryFilter,
  selectedRegion, // Added selectedRegion
}) => {
  const [reports, setReports] = useState([]);
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    // Fetch reports data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        const filteredReports = data.reports.filter(
          (report) => report.fields.field_9 !== 'Protest Planned'
        );

        setReports(filteredReports);
        setOrgs(data.orgs);
      
      })
      .catch((error) => {
        console.error('Error fetching reports data:', error);
      });
  }, []);

  useEffect(() => {
    console.log("Updated orgs state:", orgs); // This will now log correctly
  }, [orgs]); // Runs every time `orgs` changes
  /*
  const filterReports = (
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter,
    startDate,
    endDate,
    selectedIndustryFilter,
    selectedRegion // Add selectedRegion to the parameters
  ) => {
    return reports.filter((report) => {
      const sector = report.fields.field_6 || [];
      const titleMatches =
        !searchTerm ||
        report.fields.Title?.toLowerCase().includes(searchTerm.toLowerCase()) ;
        const groupResponsibleMatches =
        !groupResponsible || // If no filter is applied, include all reports
        (report.fields.field_10?.toLowerCase().includes(groupResponsible.toLowerCase()) || // Check field_10
          (report.fields.GroupResponsibleLU?.length > 0 &&
            report.fields.GroupResponsibleLU.some(
              (item) =>
                item.LookupValue?.toLowerCase().includes(groupResponsible.toLowerCase())
            ))); // Check GroupResponsibleLU
      const targetedEntityMatches =
        !targetedEntity ||
        (report.fields.field_11 &&
          report.fields.field_11.toLowerCase().includes(targetedEntity.toLowerCase()));
      const dropdownFilterMatches =
        !selectedDropdownFilter ||
        (report.fields.field_9 &&
          report.fields.field_9.toLowerCase().includes(selectedDropdownFilter.toLowerCase()));

      // Region filtering logic using Region0 field
      const regionMatches =
        !selectedRegion ||
        (report.fields.Region0 && report.fields.Region0 === selectedRegion);

      // Date filtering logic
      const reportDate = new Date(report.fields.field_1_formatted);
      const startDateMatches = !startDate || reportDate >= new Date(startDate);
      const endDateMatches = !endDate || reportDate <= new Date(endDate);

      // Industry filtering logic
      const industryMatches =
        !selectedIndustryFilter ||
        (report.fields.ARIndustry &&
          report.fields.ARIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.ESIndustry &&
          report.fields.ESIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
        (report.fields.PharmaIndustry &&
          report.fields.PharmaIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase()));

      if (
        !selectedSector &&
        !searchTerm &&
        !groupResponsible &&
        !targetedEntity &&
        !selectedDropdownFilter &&
        !selectedRegion
      ) {
        return startDateMatches && endDateMatches && industryMatches; // Return all reports when no filters are selected
      }

      if (selectedSector) {
        const sectorMatches = sector.some((sec) => sec.toLowerCase() === selectedSector.toLowerCase());
        return (
          titleMatches &&
          sectorMatches &&
          groupResponsibleMatches &&
          targetedEntityMatches &&
          dropdownFilterMatches &&
          regionMatches &&
          startDateMatches &&
          endDateMatches &&
          industryMatches
        );
      }

      return (
        titleMatches &&
        groupResponsibleMatches &&
        targetedEntityMatches &&
        dropdownFilterMatches &&
        regionMatches &&
        startDateMatches &&
        endDateMatches &&
        industryMatches
      );
    });
  };

  // Apply filters
  const filteredReports = filterReports(
    reports,
    searchTerm,
    selectedSector,
    groupResponsible,
    targetedEntity,
    selectedDropdownFilter, // Pass the selected dropdown filter to the filtering function
    startDate,
    endDate,
    selectedIndustryFilter,
    selectedRegion // Pass selectedRegion to the filtering function
  );
*/

const filterReports = (
  reports,
  searchTerm,
  selectedSector,
  groupResponsible,
  targetedEntity,
  selectedDropdownFilter,
  startDate,
  endDate,
  selectedIndustryFilter,
  selectedRegion,
  orgs 
) => {
  // Normalize the search term
  const groupResponsibleLower = groupResponsible?.toLowerCase();

  // Set to store all related Group Responsible names for broader search
  let relatedGroupNames = new Set();

  if (groupResponsibleLower && orgs.length > 0) {
    // Collect all possible matching names
    orgs.forEach(org => {
      const title = org.fields?.Title?.toLowerCase();
      const aka = org.fields?.AKA?.toLowerCase();
      const oldName = org.fields?.OldName?.toLowerCase();

      // Check if input is part of any of the fields (partial match)
      if (title?.includes(groupResponsibleLower) || 
          aka?.includes(groupResponsibleLower) || 
          oldName?.includes(groupResponsibleLower)) {
        
        // Add all relevant names for broader filtering
        if (title) relatedGroupNames.add(title);
        if (aka) relatedGroupNames.add(aka);
        if (oldName) relatedGroupNames.add(oldName);
      }
    });

    // If no exact match found, use the input itself to allow partial search
    if (relatedGroupNames.size === 0) {
      relatedGroupNames.add(groupResponsibleLower);
    }
  }

  console.log("Related Group Names (for filtering):", [...relatedGroupNames]);

  return (reports || []).filter((report) => {
    if (!report.fields) return false; // Ensure report.fields exists

    const sector = report.fields.field_6 || [];
    const searchTermLower = searchTerm?.toLowerCase();

    const titleMatches = 
      !searchTerm || 
      report.fields.Title?.toLowerCase().includes(searchTermLower);

    // Modify this to allow partial matching for GR search
    const groupResponsibleMatches = 
      !groupResponsible || 
      [...relatedGroupNames].some(name =>
        report.fields.field_10?.toLowerCase().includes(name) || // Partial match in field_10
        (report.fields.GroupResponsibleLU?.some(item =>
          item.LookupValue?.toLowerCase().includes(name) // Partial match in GroupResponsibleLU
        ))
      );

    const targetedEntityMatches =
      !targetedEntity ||
      (report.fields.field_11 &&
        report.fields.field_11.toLowerCase().includes(targetedEntity.toLowerCase()));

    const dropdownFilterMatches =
      !selectedDropdownFilter ||
      (report.fields.field_9 &&
        report.fields.field_9.toLowerCase().includes(selectedDropdownFilter.toLowerCase()));

    const regionMatches =
      !selectedRegion ||
      (report.fields.Region0 && report.fields.Region0 === selectedRegion);

    const reportDate = new Date(report.fields.field_1_formatted);
    const startDateMatches = !startDate || reportDate >= new Date(startDate);
    const endDateMatches = !endDate || reportDate <= new Date(endDate);

    const industryMatches =
      !selectedIndustryFilter ||
      (report.fields.ARIndustry &&
        report.fields.ARIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
      (report.fields.ESIndustry &&
        report.fields.ESIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase())) ||
      (report.fields.PharmaIndustry &&
        report.fields.PharmaIndustry.toLowerCase().includes(selectedIndustryFilter.toLowerCase()));

    if (
      !selectedSector &&
      !searchTerm &&
      !groupResponsible &&
      !targetedEntity &&
      !selectedDropdownFilter &&
      !selectedRegion
    ) {
      return startDateMatches && endDateMatches && industryMatches;
    }

    if (selectedSector) {
      const sectorMatches = sector.some((sec) => sec.toLowerCase().includes(selectedSector.toLowerCase()));
      return (
        titleMatches &&
        sectorMatches &&
        groupResponsibleMatches &&
        targetedEntityMatches &&
        dropdownFilterMatches &&
        regionMatches &&
        startDateMatches &&
        endDateMatches &&
        industryMatches
      );
    }

    return (
      titleMatches &&
      groupResponsibleMatches &&
      targetedEntityMatches &&
      dropdownFilterMatches &&
      regionMatches &&
      startDateMatches &&
      endDateMatches &&
      industryMatches
    );
  });
};

// Apply filters
const filteredReports = filterReports(
  reports,
  searchTerm,
  selectedSector,
  groupResponsible,
  targetedEntity,
  selectedDropdownFilter,
  startDate,
  endDate,
  selectedIndustryFilter,
  selectedRegion,
  orgs // Pass the orgs array for lookup
);


  return (
    <div className="reports-grid">
      <div className="reports-header">
        <div className="header-item">Title</div>
        <div className="header-item">Report Date</div>
        <div className="header-item">Incident Date</div>
        <div className="header-item">Country</div>
        <div className="header-item">Sector</div>
      </div>
      {filteredReports.map((report) => (
        <div className="report-row" key={report.fields.id}>
          {report.fields.Links1 ? (
            <a
              href={report.fields.Links1} // Set the href to the Links1 column value
              target="_blank" // Open the link in a new tab/window
              rel="noopener noreferrer"
              className="report-item report-title" // Add a CSS class for styling
            >
              {report.fields.Title}
            </a>
          ) : (
            <div className="report-item">{report.fields.Title}</div>
          )}
          <div className="report-item">{report.fields.field_1_formatted}</div>
          <div className="report-item">{report.fields.field_2_formatted}</div>
          <div className="report-item">{report.fields.field_3}</div>
          <div className="report-item">{report.fields.field_6}</div>
        </div>
      ))}
    </div>
  );
};

export default ReportsGrid;
