import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import EmailFormModal from '../Components/EmailModal.js'; // Import the modal component
import '../CSS/Orgs.css';

const OrgDetailsPage = () => {
  const { id } = useParams(); // Get the ID from the URL parameter

  const [orgs, setOrgs] = useState([]);
  const [reports, setReports] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    // Fetch orgs data from your server
    fetch('/api/hello')
      .then((response) => response.json())
      .then((data) => {
        setOrgs(data.orgs);
        setReports(data.reports);
      })
      .catch((error) => {
        console.error('Error fetching orgs data:', error);
      });
  }, []);

  // Find the organization with the matching ID
  const selectedOrg = orgs.find((org) => org.fields.id === id);

  if (!selectedOrg) {
    return <div>Organization not found</div>;
  }

  const profileLink = selectedOrg.fields.ProfileLink;

  const orgTitle = selectedOrg.fields.Title;

  const akaDash = '- ' + selectedOrg.fields.AKA;



  // Filter reports based on the specified field that links to the organization
  const orgReports = reports.filter(
    (report) => report.fields.field_10 === orgTitle || report.fields.field_10 === selectedOrg.fields.OldName
  );

  // Handlers for modal open/close
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="orgs">
      <h1>{selectedOrg.fields.Title} {selectedOrg.fields.AKA && akaDash}</h1>
      {selectedOrg.fields.OldName && <h2>Prior Name : {selectedOrg.fields.OldName}</h2>}
      {/* Display other details of the selected organization */}
      {/* For example: <p>Description: {selectedOrg.fields.Description}</p> */}
      {profileLink ? (
        <a
          href={profileLink}
          className="profiles"
          target="_blank"
          rel="noopener noreferrer"
        >
          Link to Organization Profile
        </a>
      ) : (
        <p>
          No profile exists.{' '}
          <button className="contact-button" onClick={handleOpenModal}>
            Click here to contact us.
          </button>
        </p>
      )}

      {/* Display reports related to the organization */}
      <h3>Reports Involving {selectedOrg.fields.Title}</h3>
      <div className="reports-grid">
        <div className="reports-header">
          <div className="header-item">Title</div>
          <div className="header-item">Date</div>
          <div className="header-item">Country</div>
          <div className="header-item">Sector</div>
        </div>
        {orgReports.map((report) => (
          <div className="report-row" key={report.fields.Id}>
            {report.fields.Links1 ? (
              <a
                href={report.fields.Links1}
                target="_blank"
                rel="noopener noreferrer"
                className="report-item report-title"
              >
                {report.fields.Title}
              </a>
            ) : (
              <div className="report-item">{report.fields.Title}</div>
            )}
            <div className="report-item">{report.fields.field_1_formatted}</div>
            <div className="report-item">{report.fields.field_3}</div>
            <div className="report-item">{report.fields.field_6}</div>
          </div>
        ))}
      </div>

      {/* Modal for Email Form */}
      <EmailFormModal 
      isOpen={isModalOpen} 
      onClose={handleCloseModal}
      orgName={selectedOrg.fields.Title}
      />
    </div>
  );
};

export default OrgDetailsPage;
